.invoice-container {
  background: #ffffff;
  padding: 80px 24px;
}
.inv-details-container {
  width: 380px;
  max-width: 100%;
  margin: 0px auto;
  background: #f8f8fa;
}
.inv-details-text {
  text-align: center;
  font-weight: bold;
  padding: 16px 0;
  font-size: 16px;
}
.details-wrapper {
  padding: 20px 8px;
  background: #ffffff;
  border: 6px solid #f8f8fa;
  border-top: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.due-on-wrapper {
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 4px;
}
.due-for-wrapper {
  display: flex;
  justify-content: space-between;
}
.due-on-wrapper > .title,
.due-for-wrapper > .title {
  font-weight: bold;
}
.field_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  width: 380px;
  max-width: 100%;
  margin: 0px auto;
}
.input-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.input-wrapper > label {
  font-size: 16px;
}
.input-box {
  font-size: 16px;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid #b3b3b3;
  text-align: right;
}
.amount-wrap {
  display: flex;
  flex-direction: column;
}
.amount-wrap > span {
  text-align: right;
  font-size: 10px;
}

.radio-group-container {
  margin-top: 16px;
}
.radio-group-label {
  font-size: 16px;
  font-weight: bold;
}

.radio-container {
  display: flex;
  gap: 24px;
  margin: 16px 0;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.radio-input {
  appearance: none;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 2px solid #267496;
  border-radius: 50%;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
  position: relative;
}
.radio-input:checked {
  background-color: #267496;
}

.radio-input::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin: auto;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.radio-input:checked::before {
  visibility: visible;
}

.radio-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.submit-button {
  background-color: #267496;
  color: #fff;
  border: none;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.submit-btn {
  display: flex;
  justify-content: flex-end;
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}